<template>
  <div>
    <meta-actions-header class="mb-12" :is-loading="isLoading">
      <template v-if="!isLoading" #header>
        <breadcrumb class="flex flex-1 text-gray-700"
          >: <span v-if="platformDeal" class="text-orange-500">{{ platformDeal.name }}</span>
        </breadcrumb>
      </template>
    </meta-actions-header>

    <tabs
      v-if="!isLoading"
      :platform-deal="platformDeal"
      :tabs-data="tabs"
      @locked="onTabLocked"
      @dirty="onTabDirty"
      @update="onDealInfoUpdate"
    />

    <card v-if="isLoading">
      <card-form-loading></card-form-loading>
      <card-form-loading class="mt-10"></card-form-loading>
      <card-form-loading class="mt-10"></card-form-loading>
      <card-form-loading class="mt-10"></card-form-loading>
    </card>
  </div>
</template>
<script>
import { mapActions } from 'vuex';
import { CREATE_TOAST } from '@/store/modules/toast/keys';
import metaInfo from '@/mixins/common/metaInfo';
import { Toast } from '@/model/shared/Toast';

import MetaActionsHeader from '@/components/organisms/shared/MetaActionsHeader';
import CardFormLoading from '@/components/atoms/CardFormLoading';
import Card from '@/components/atoms/Card/Card';

import CONFIG from '@/components/organisms/modules/dashboardSSP/deal/form/config';
import Tabs from '@/components/atoms/Tabs';

import PlatformDeal from '@/entities/dashboardSSP/PlatformDeals';
import Breadcrumb from '@/components/atoms/Breadcrumb';

export default {
  name: 'PlatformsDealForm',
  components: {
    Breadcrumb,
    MetaActionsHeader,
    Tabs,
    CardFormLoading,
    Card,
  },
  mixins: [metaInfo],
  props: {
    isLoading: { type: Boolean, default: () => false },
    entity: { type: PlatformDeal, default: () => null },
  },
  data: () => ({
    hasError: false,
    tabs: CONFIG.tabs,
    platformDeal: new PlatformDeal(),
  }),
  computed: {},
  watch: {
    // Avoid user can access to the edit form with empty tab redirecting him
    async $route(newRoute) {
      if (!newRoute.params?.platformDealId) {
        await this.redirectToFirstTab();
      }
    },
    entity: {
      immediate: true,
      async handler(entity) {
        this.platformDeal = entity;
      },
    },
  },
  async mounted() {
    await this.redirectToFirstTab();
  },
  methods: {
    ...mapActions({ createToast: CREATE_TOAST }),
    metaTitleReplacement() {
      return { deal: this.platformDeal?.name } || {};
    },
    async redirectToFirstTab() {
      if (this.$route.name !== this.tabs[0].route.name) {
        await this.$router.push(this.tabs[0].route);
      }
    },
    async onDealInfoUpdate(entity) {
      this.$emit('update', entity);
    },
    onTabLocked(tab) {
      this.createToast(
        Toast.warning('Locked!', `To view the ${tab.name} section. You must complete the offer basic info`)
      );
    },
    onTabDirty() {
      this.createToast(Toast.warning('Warning!', `You have unsaved work`));
    },
  },
};
</script>
