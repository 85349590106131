<template>
  <platforms-deal-form :entity="platformDeal" :is-loading="isLoading" @update="loadPlatformDeal"></platforms-deal-form>
</template>

<script>
import { mapActions } from 'vuex';
import { CREATE_TOAST } from '@/store/modules/toast/keys';
import { Toast } from '@/model/shared/Toast';
import PlatformsDealForm from '@/components/organisms/modules/dashboardSSP/platformsDeal/form/PlatformsDealForm';
import metaInfo from '@/mixins/common/metaInfo';
import { list } from '@/router/private/modules/dashboardSSP/supply/platformsDeals/list';
import { getPlatformDealById } from '@/services/modules/dashboardSSP/platformDeals';
import { HTTPStatusCode } from '@/model/shared/HTTPStatusCode';

export default {
  name: 'PlatformsDealEdit',
  components: {
    PlatformsDealForm,
  },
  mixins: [metaInfo],
  props: {
    platformDealId: { type: String, default: () => null },
  },
  data: () => ({
    platformDeal: null,
    isLoading: true,
  }),
  created() {
    this.loadPlatformDeal();
  },
  methods: {
    ...mapActions({ createToast: CREATE_TOAST }),
    async loadPlatformDeal() {
      try {
        if (!this.platformDealId) return;
        this.isLoading = true;

        const { data } = await getPlatformDealById(this.platformDealId);
        this.platformDeal = data;
        this.updateTitle();
        this.isLoading = false;
      } catch (error) {
        if (error.code !== HTTPStatusCode.Cancel) {
          this.createToast(Toast.error(`Can't get deal`, error.message));
          await this.$router.push(list);
        } else {
          this.isLoading = false;
        }
      }
    },
  },
};
</script>
