// import FILTERS_CONFIG from '@/model/modules/dashboardSSP/filtersConfig.js';
import Column from '@/model/shared/Column';

export const COLUMNS = {
  NAME: new Column('NAME').setClass('truncate break-normal font-bold'),
  LINK_STATUS: new Column('LINK STATUS', 'deal.syncStatus', true, false, []),
  TYPE: new Column('TYPE', 'deal.type', true, false, []),
  STATUS: new Column('STATUS', 'deal.status', true, false, []),
  PLATFORM: new Column('PLATFORM'),
  SELLER: new Column('SELLER', 'deal.seller.email'),
  ACTIONS: new Column('ACTIONS', 'actions', true, false, [], 'w-4'),
};

export default {
  columns: Object.values(COLUMNS),
  filters: [
    // FILTERS_CONFIG.DEAL_TYPE,
    // FILTERS_CONFIG.LINK_STATUS,
    // FILTERS_CONFIG.NAME,
    // FILTERS_CONFIG.PLATFORM_ID,
    // FILTERS_CONFIG.SALESFORCE_SELLER_ID,
    // FILTERS_CONFIG.STATUS,
  ],
};
