<template>
  <div>
    <asterix-section :status="sectionStatus">
      <template #content>
        <sun-filter-layout
          id="filter-box"
          :filters-added="filterFind"
          :filters-available="availableFilters"
          :has-error="anyError"
          show-reset
          @change="filtersSetFiltersFind"
          @close="filtersResetErrors"
          @remove="filtersResetErrors"
          @reset="resetFilters"
        >
          <template #quickFilters>
            <div class="min-w-max">
              <sun-search-input class-input="text-sm" :value="filterQuick['name']" @search="onQuickFiltersSearch" />
            </div>
          </template>
          <!--Filters Slot :Start -->
          <template #[`filter.name`]="{ filter, onSelect, isDuplicate }">
            <sun-input
              type="text"
              :text-error="filtersGetFilterError(filter, isDuplicate)"
              :error="!!filtersGetFilterError(filter, isDuplicate)"
              @change="filtersOnSelectFilter(filter, $event.value, onSelect)"
              @enter="filtersOnSelectFilter(filter, $event.value, onSelect)"
            />
          </template>
          <template #[`filter.deal.type`]="{ filter, onSelect, value, isDuplicate }">
            <asterix-async-select
              id="seller-select-filter"
              name="seller-select-filter"
              track-by="name"
              label="name"
              class="w-24"
              is-array-object
              disable-selected-options
              close-on-select
              :service="filter.service"
              class-input="pt-0 shadow-none rounded-none multiselect"
              :value="filtersMakeFiltersForSelect(value)"
              :text-error="filtersGetFilterError(filter, isDuplicate)"
              :error="!!filtersGetFilterError(filter, isDuplicate)"
              @change="filtersOnSelectFilter(filter, $event.items.name, onSelect)"
            />
          </template>
          <template #[`filter.status`]="{ filter, onSelect, value, isDuplicate }">
            <sun-select
              :options="filter.options"
              track-by="value"
              label="name"
              :value="filtersMakeFiltersForSelect(value)"
              class="w-24"
              add-hex-color="orange"
              class-input="pt-0 shadow-none rounded-none multiselect"
              :text-error="filtersGetFilterError(filter, isDuplicate)"
              :error="!!filtersGetFilterError(filter, isDuplicate)"
              @change="filtersOnSelectFilter(filter, $event.value, onSelect)"
            ></sun-select>
          </template>
          <template #[`filter.link.status`]="{ filter, onSelect, value, isDuplicate }">
            <sun-select
              :options="filter.options"
              track-by="id"
              label="name"
              :value="filtersMakeFiltersForSelect(value)"
              class="w-24"
              add-hex-color="orange"
              class-input="pt-0 shadow-none rounded-none multiselect"
              :text-error="filtersGetFilterError(filter, isDuplicate)"
              :error="!!filtersGetFilterError(filter, isDuplicate)"
              @change="filtersOnSelectFilter(filter, $event.value, onSelect)"
            />
          </template>
          <template #[`filter.platform.id`]="{ filter, onSelect, value, isDuplicate }">
            <asterix-async-select
              id="platform-select-filter"
              name="platform-select-filter"
              :track-by="filter.trackBy"
              :label="filter.textBy"
              class="w-24"
              is-array-object
              disable-selected-options
              close-on-select
              :service="filter.service(activeClient.id)"
              class-input="pt-0 shadow-none rounded-none"
              :value="filtersMakeFiltersForSelect(value)"
              :text-error="filtersGetFilterError(filter, isDuplicate)"
              :error="!!filtersGetFilterError(filter, isDuplicate)"
              @change="filtersOnSelectFilter(filter, $event.items, onSelect)"
            />
          </template>
          <template #[`filter.deal.sellerId`]="{ filter, onSelect, value, isDuplicate }">
            <asterix-async-select
              id="seller-select-filter"
              name="seller-select-filter"
              track-by="id"
              label="name"
              class="w-24"
              is-array-object
              disable-selected-options
              close-on-select
              :service="filter.service"
              class-input="pt-0 shadow-none rounded-none multiselect"
              :value="filtersMakeFiltersForSelect(value)"
              :text-error="filtersGetFilterError(filter, isDuplicate)"
              :error="!!filtersGetFilterError(filter, isDuplicate)"
              @change="filtersOnSelectFilter(filter, $event.items, onSelect)"
            />
          </template>
        </sun-filter-layout>

        <sun-data-table
          :headers="headers"
          :content="items"
          hoverable
          :loading="isLoading"
          class="mt-2"
          @sort="onSortTable"
        >
          <template #[`col.name`]="{ item, columnClass }">
            <sun-data-table-cell width="200" :class="columnClass">
              <span :title="item.name">{{ item.name }}</span>
            </sun-data-table-cell>
          </template>
          <template #[`col.deal.syncStatus`]="{ item }">
            <sun-data-table-cell>
              <a href="javascript:;" @click="showSyncWithSalesforceModal(item)">
                <status-pill v-if="item.deal.id" color="green" :status="item.deal.idOpportunity" />
                <status-pill v-else :status="item.deal.syncStatus" />
              </a>
            </sun-data-table-cell>
          </template>

          <template #[`col.deal.type`]="{ item }">
            <sun-data-table-cell>
              {{ item.deal.type || '-' }}
            </sun-data-table-cell>
          </template>

          <template #[`col.deal.status`]="{ item }">
            <sun-data-table-cell>
              <status-pill
                v-if="item.deal.status.value"
                :status="item.deal.status.value"
                :color="item.deal.status.color"
              />
              <span v-else>-</span>
            </sun-data-table-cell>
          </template>

          <template #[`col.platform`]="{ item }">
            <sun-data-table-cell>
              {{ item.platform.name }}
            </sun-data-table-cell>
          </template>

          <template #[`col.deal.seller.email`]="{ item }">
            <sun-data-table-cell>
              {{ salesforceUserEmailList[item.deal.seller.id] || notAvailable }}
            </sun-data-table-cell>
          </template>

          <template #[`col.actions`]="{ item, columnClass }">
            <sun-data-table-cell :class="columnClass">
              <table-action-menu :actions="actions" :item="item" :items="items" @click="onActionClick($event, item)" />
            </sun-data-table-cell>
          </template>

          <template #empty>
            <asterix-no-data class="bg-white" />
          </template>
        </sun-data-table>

        <sun-pagination
          :key="currentPage"
          class="justify-center mt-12"
          :total-pages="totalPages"
          :current-page="currentPage"
          @change="goToPage"
        />
      </template>
    </asterix-section>
    <asterix-form-modal
      v-if="syncWithSalesforceModel"
      closable
      title="Sync with Salesforce"
      @cancel="syncWithSalesforceModel = false"
      @submit="submitSyncWithSalesforce"
    >
      <template #content>
        <div style="height: 150px">
          <sun-label-group text="Transaction ID">
            <asterix-async-select
              v-model="editFrom.deal"
              class="z-101"
              name="id-opportunity-select"
              track-by="id"
              label="name"
              required="required"
              :disabled="disableSync"
              is-array-object
              disable-selected-options
              close-on-select
              :service="getSalesforceByOpportunity"
              @change="setDealToPlatform"
            >
              <template #option="{ option }">
                {{ option | dealNameAndTransactionIDLabel }}
              </template>
            </asterix-async-select>
          </sun-label-group>
          <div v-show="editFrom.idOpportunity" class="flex flex-row justify-start wrap mt-4">
            <sun-pill close color="gray" @close="removeTransactionId">{{ editFrom.idOpportunity }} </sun-pill>
          </div>
        </div>
      </template>
      <template #footer>
        <div class="flex flex-row justify-between">
          <save-button :disabled="disableSync" />
          <sun-button
            class="bg-gray-700 text-white rounded shadow-md hover:bg-gray-900 custom-p-1"
            color="red"
            variant="pill"
            :disabled="disableSync"
            @click="syncWithSalesforceModel = false"
          >
            <span class="text-sm">Cancel</span>
          </sun-button>
        </div>
      </template>
    </asterix-form-modal>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import { Toast } from '@/model/shared/Toast';
import { CREATE_TOAST } from '@/store/modules/toast/keys';
import { indexMixin } from '@/mixins/index/indexMixin';
import filtersMixin from '@/mixins/filters/filtersMixin';
import AsterixSection from '@/components/templates/AsterixSection';
import { activeClientMixin } from '@/mixins/common/activeClientMixin';
import { edit as editPlatformsDeal } from '@/router/private/modules/dashboardSSP/supply/platformsDeals/edit';
import AsterixAsyncSelect from '@/components/molecules/shared/AsterixAsyncSelect';
import AsterixFormModal from '@/components/organisms/shared/AsterixFormModal.vue';
import SaveButton from '@/components/atoms/SaveButton';
import { getPlatformDeals, updatePlatformDealSync } from '@/services/modules/dashboardSSP/platformDeals';
import { getDealByNameOrOpportunityId } from '@/services/modules/dashboardSSP/deal';
import CONFIG from './config';
import getSaleforceUsersForDealsMixin from '@/mixins/modules/dashboardSSP/getSaleforceUsersForDealsMixin';
import { USER } from '@/store/modules/auth/keys';
import { stringToDate } from '@/filters/dateFilters';

export default {
  name: 'PlatformsDealList',
  components: {
    SaveButton,
    AsterixSection,
    AsterixFormModal,
    AsterixAsyncSelect,
    TableActionMenu: () => import('@/components/organisms/shared/TableActionMenu'),
    AsterixNoData: () => import('@/components/organisms/shared/AsterixNoData'),
    StatusPill: () => import('@/components/atoms/StatusPill'),
  },
  filters: {
    dealNameAndTransactionIDLabel: deal => `${deal.name} (${deal.idOpportunity})`,
  },
  mixins: [
    activeClientMixin,
    filtersMixin({
      filters: CONFIG.filters,
      filterQuick: {
        name: undefined,
      },
    }),
    indexMixin,
    getSaleforceUsersForDealsMixin,
  ],
  data: () => ({
    headers: CONFIG.columns,
    items: [],
    notAvailable: 'N/A',
    actions: [{ name: 'Edit' }, { name: 'Sync' }],
    syncWithSalesforceModel: false,
    editFrom: {
      idOpportunity: null,
      platformDeal: null,
      deal: null,
    },
    disableSync: false,
  }),
  computed: {
    ...mapGetters({
      user: USER,
    }),
  },
  created() {
    this.filterDefault = [{ name: 'client.id', value: this.activeClient?.id }];
  },
  async mounted() {
    // load filters before api request
    await this.filtersLoadAllfiltersOnMounted();

    await this.getTableItems();
  },
  methods: {
    ...mapActions({ createToast: CREATE_TOAST }),

    onActionClick(event, platformDeal) {
      switch (event.name) {
        case 'Edit':
          this.editDeal(platformDeal.id);
          break;
        case 'Sync':
          this.showSyncWithSalesforceModal(platformDeal);
          break;
      }
    },
    editDeal(platformDealId) {
      const route = { name: editPlatformsDeal.name, params: { platformDealId } };
      this.$router.push(route);
    },
    async getTableItems() {
      this.isLoading = true;
      const { data, isCancel } = await this.getItemsFromAPI(getPlatformDeals);
      this.items = data;
      if (!isCancel) {
        this.isLoading = false;
      }
      await this.setSellers('deal.seller');
    },
    filtersOnSelectFilterDate(value, onSelect) {
      const date = stringToDate(value.startDate).toISOString();
      this.filtersOnSelectFilter(date, onSelect);
    },
    showSyncWithSalesforceModal(platformDeal) {
      this.syncWithSalesforceModel = true;
      this.editFrom = {
        idOpportunity: platformDeal.deal?.idOpportunity || null,
        platformDeal,
        deal: null,
      };
    },
    getSalesforceByOpportunity(params) {
      const query = params.filters.name;
      delete params.filters.name;
      return getDealByNameOrOpportunityId(query, params);
    },
    setDealToPlatform({ items }) {
      this.editFrom.deal = items;
    },
    async submitSyncWithSalesforce({ valid }) {
      try {
        if (!valid) return;
        this.disableSync = true;
        await updatePlatformDealSync(this.editFrom.platformDeal.id, this.editFrom.deal.id);
        this.createToast(Toast.success('Data updated', 'Data was updated successfully'));
        this.syncWithSalesforceModel = false;
        this.getTableItems();
      } catch (e) {
        this.createToast(Toast.error('Deal could not be updated', e.message));
      } finally {
        this.disableSync = false;
      }
    },
    async removeTransactionId() {
      this.editFrom.idOpportunity = null;
    },
  },
};
</script>
