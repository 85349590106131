var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c("asterix-section", {
        attrs: { status: _vm.sectionStatus },
        scopedSlots: _vm._u([
          {
            key: "content",
            fn: function () {
              return [
                _c("sun-filter-layout", {
                  attrs: {
                    id: "filter-box",
                    "filters-added": _vm.filterFind,
                    "filters-available": _vm.availableFilters,
                    "has-error": _vm.anyError,
                    "show-reset": "",
                  },
                  on: {
                    change: _vm.filtersSetFiltersFind,
                    close: _vm.filtersResetErrors,
                    remove: _vm.filtersResetErrors,
                    reset: _vm.resetFilters,
                  },
                  scopedSlots: _vm._u(
                    [
                      {
                        key: "quickFilters",
                        fn: function () {
                          return [
                            _c(
                              "div",
                              { staticClass: "min-w-max" },
                              [
                                _c("sun-search-input", {
                                  attrs: {
                                    "class-input": "text-sm",
                                    value: _vm.filterQuick["name"],
                                  },
                                  on: { search: _vm.onQuickFiltersSearch },
                                }),
                              ],
                              1
                            ),
                          ]
                        },
                        proxy: true,
                      },
                      {
                        key: `filter.name`,
                        fn: function ({ filter, onSelect, isDuplicate }) {
                          return [
                            _c("sun-input", {
                              attrs: {
                                type: "text",
                                "text-error": _vm.filtersGetFilterError(
                                  filter,
                                  isDuplicate
                                ),
                                error: !!_vm.filtersGetFilterError(
                                  filter,
                                  isDuplicate
                                ),
                              },
                              on: {
                                change: function ($event) {
                                  return _vm.filtersOnSelectFilter(
                                    filter,
                                    $event.value,
                                    onSelect
                                  )
                                },
                                enter: function ($event) {
                                  return _vm.filtersOnSelectFilter(
                                    filter,
                                    $event.value,
                                    onSelect
                                  )
                                },
                              },
                            }),
                          ]
                        },
                      },
                      {
                        key: `filter.deal.type`,
                        fn: function ({
                          filter,
                          onSelect,
                          value,
                          isDuplicate,
                        }) {
                          return [
                            _c("asterix-async-select", {
                              staticClass: "w-24",
                              attrs: {
                                id: "seller-select-filter",
                                name: "seller-select-filter",
                                "track-by": "name",
                                label: "name",
                                "is-array-object": "",
                                "disable-selected-options": "",
                                "close-on-select": "",
                                service: filter.service,
                                "class-input":
                                  "pt-0 shadow-none rounded-none multiselect",
                                value: _vm.filtersMakeFiltersForSelect(value),
                                "text-error": _vm.filtersGetFilterError(
                                  filter,
                                  isDuplicate
                                ),
                                error: !!_vm.filtersGetFilterError(
                                  filter,
                                  isDuplicate
                                ),
                              },
                              on: {
                                change: function ($event) {
                                  return _vm.filtersOnSelectFilter(
                                    filter,
                                    $event.items.name,
                                    onSelect
                                  )
                                },
                              },
                            }),
                          ]
                        },
                      },
                      {
                        key: `filter.status`,
                        fn: function ({
                          filter,
                          onSelect,
                          value,
                          isDuplicate,
                        }) {
                          return [
                            _c("sun-select", {
                              staticClass: "w-24",
                              attrs: {
                                options: filter.options,
                                "track-by": "value",
                                label: "name",
                                value: _vm.filtersMakeFiltersForSelect(value),
                                "add-hex-color": "orange",
                                "class-input":
                                  "pt-0 shadow-none rounded-none multiselect",
                                "text-error": _vm.filtersGetFilterError(
                                  filter,
                                  isDuplicate
                                ),
                                error: !!_vm.filtersGetFilterError(
                                  filter,
                                  isDuplicate
                                ),
                              },
                              on: {
                                change: function ($event) {
                                  return _vm.filtersOnSelectFilter(
                                    filter,
                                    $event.value,
                                    onSelect
                                  )
                                },
                              },
                            }),
                          ]
                        },
                      },
                      {
                        key: `filter.link.status`,
                        fn: function ({
                          filter,
                          onSelect,
                          value,
                          isDuplicate,
                        }) {
                          return [
                            _c("sun-select", {
                              staticClass: "w-24",
                              attrs: {
                                options: filter.options,
                                "track-by": "id",
                                label: "name",
                                value: _vm.filtersMakeFiltersForSelect(value),
                                "add-hex-color": "orange",
                                "class-input":
                                  "pt-0 shadow-none rounded-none multiselect",
                                "text-error": _vm.filtersGetFilterError(
                                  filter,
                                  isDuplicate
                                ),
                                error: !!_vm.filtersGetFilterError(
                                  filter,
                                  isDuplicate
                                ),
                              },
                              on: {
                                change: function ($event) {
                                  return _vm.filtersOnSelectFilter(
                                    filter,
                                    $event.value,
                                    onSelect
                                  )
                                },
                              },
                            }),
                          ]
                        },
                      },
                      {
                        key: `filter.platform.id`,
                        fn: function ({
                          filter,
                          onSelect,
                          value,
                          isDuplicate,
                        }) {
                          return [
                            _c("asterix-async-select", {
                              staticClass: "w-24",
                              attrs: {
                                id: "platform-select-filter",
                                name: "platform-select-filter",
                                "track-by": filter.trackBy,
                                label: filter.textBy,
                                "is-array-object": "",
                                "disable-selected-options": "",
                                "close-on-select": "",
                                service: filter.service(_vm.activeClient.id),
                                "class-input": "pt-0 shadow-none rounded-none",
                                value: _vm.filtersMakeFiltersForSelect(value),
                                "text-error": _vm.filtersGetFilterError(
                                  filter,
                                  isDuplicate
                                ),
                                error: !!_vm.filtersGetFilterError(
                                  filter,
                                  isDuplicate
                                ),
                              },
                              on: {
                                change: function ($event) {
                                  return _vm.filtersOnSelectFilter(
                                    filter,
                                    $event.items,
                                    onSelect
                                  )
                                },
                              },
                            }),
                          ]
                        },
                      },
                      {
                        key: `filter.deal.sellerId`,
                        fn: function ({
                          filter,
                          onSelect,
                          value,
                          isDuplicate,
                        }) {
                          return [
                            _c("asterix-async-select", {
                              staticClass: "w-24",
                              attrs: {
                                id: "seller-select-filter",
                                name: "seller-select-filter",
                                "track-by": "id",
                                label: "name",
                                "is-array-object": "",
                                "disable-selected-options": "",
                                "close-on-select": "",
                                service: filter.service,
                                "class-input":
                                  "pt-0 shadow-none rounded-none multiselect",
                                value: _vm.filtersMakeFiltersForSelect(value),
                                "text-error": _vm.filtersGetFilterError(
                                  filter,
                                  isDuplicate
                                ),
                                error: !!_vm.filtersGetFilterError(
                                  filter,
                                  isDuplicate
                                ),
                              },
                              on: {
                                change: function ($event) {
                                  return _vm.filtersOnSelectFilter(
                                    filter,
                                    $event.items,
                                    onSelect
                                  )
                                },
                              },
                            }),
                          ]
                        },
                      },
                    ],
                    null,
                    true
                  ),
                }),
                _c("sun-data-table", {
                  staticClass: "mt-2",
                  attrs: {
                    headers: _vm.headers,
                    content: _vm.items,
                    hoverable: "",
                    loading: _vm.isLoading,
                  },
                  on: { sort: _vm.onSortTable },
                  scopedSlots: _vm._u(
                    [
                      {
                        key: `col.name`,
                        fn: function ({ item, columnClass }) {
                          return [
                            _c(
                              "sun-data-table-cell",
                              { class: columnClass, attrs: { width: "200" } },
                              [
                                _c("span", { attrs: { title: item.name } }, [
                                  _vm._v(_vm._s(item.name)),
                                ]),
                              ]
                            ),
                          ]
                        },
                      },
                      {
                        key: `col.deal.syncStatus`,
                        fn: function ({ item }) {
                          return [
                            _c("sun-data-table-cell", [
                              _c(
                                "a",
                                {
                                  attrs: { href: "javascript:;" },
                                  on: {
                                    click: function ($event) {
                                      return _vm.showSyncWithSalesforceModal(
                                        item
                                      )
                                    },
                                  },
                                },
                                [
                                  item.deal.id
                                    ? _c("status-pill", {
                                        attrs: {
                                          color: "green",
                                          status: item.deal.idOpportunity,
                                        },
                                      })
                                    : _c("status-pill", {
                                        attrs: { status: item.deal.syncStatus },
                                      }),
                                ],
                                1
                              ),
                            ]),
                          ]
                        },
                      },
                      {
                        key: `col.deal.type`,
                        fn: function ({ item }) {
                          return [
                            _c("sun-data-table-cell", [
                              _vm._v(" " + _vm._s(item.deal.type || "-") + " "),
                            ]),
                          ]
                        },
                      },
                      {
                        key: `col.deal.status`,
                        fn: function ({ item }) {
                          return [
                            _c(
                              "sun-data-table-cell",
                              [
                                item.deal.status.value
                                  ? _c("status-pill", {
                                      attrs: {
                                        status: item.deal.status.value,
                                        color: item.deal.status.color,
                                      },
                                    })
                                  : _c("span", [_vm._v("-")]),
                              ],
                              1
                            ),
                          ]
                        },
                      },
                      {
                        key: `col.platform`,
                        fn: function ({ item }) {
                          return [
                            _c("sun-data-table-cell", [
                              _vm._v(" " + _vm._s(item.platform.name) + " "),
                            ]),
                          ]
                        },
                      },
                      {
                        key: `col.deal.seller.email`,
                        fn: function ({ item }) {
                          return [
                            _c("sun-data-table-cell", [
                              _vm._v(
                                " " +
                                  _vm._s(
                                    _vm.salesforceUserEmailList[
                                      item.deal.seller.id
                                    ] || _vm.notAvailable
                                  ) +
                                  " "
                              ),
                            ]),
                          ]
                        },
                      },
                      {
                        key: `col.actions`,
                        fn: function ({ item, columnClass }) {
                          return [
                            _c(
                              "sun-data-table-cell",
                              { class: columnClass },
                              [
                                _c("table-action-menu", {
                                  attrs: {
                                    actions: _vm.actions,
                                    item: item,
                                    items: _vm.items,
                                  },
                                  on: {
                                    click: function ($event) {
                                      return _vm.onActionClick($event, item)
                                    },
                                  },
                                }),
                              ],
                              1
                            ),
                          ]
                        },
                      },
                      {
                        key: "empty",
                        fn: function () {
                          return [
                            _c("asterix-no-data", { staticClass: "bg-white" }),
                          ]
                        },
                        proxy: true,
                      },
                    ],
                    null,
                    true
                  ),
                }),
                _c("sun-pagination", {
                  key: _vm.currentPage,
                  staticClass: "justify-center mt-12",
                  attrs: {
                    "total-pages": _vm.totalPages,
                    "current-page": _vm.currentPage,
                  },
                  on: { change: _vm.goToPage },
                }),
              ]
            },
            proxy: true,
          },
        ]),
      }),
      _vm.syncWithSalesforceModel
        ? _c("asterix-form-modal", {
            attrs: { closable: "", title: "Sync with Salesforce" },
            on: {
              cancel: function ($event) {
                _vm.syncWithSalesforceModel = false
              },
              submit: _vm.submitSyncWithSalesforce,
            },
            scopedSlots: _vm._u(
              [
                {
                  key: "content",
                  fn: function () {
                    return [
                      _c(
                        "div",
                        { staticStyle: { height: "150px" } },
                        [
                          _c(
                            "sun-label-group",
                            { attrs: { text: "Transaction ID" } },
                            [
                              _c("asterix-async-select", {
                                staticClass: "z-101",
                                attrs: {
                                  name: "id-opportunity-select",
                                  "track-by": "id",
                                  label: "name",
                                  required: "required",
                                  disabled: _vm.disableSync,
                                  "is-array-object": "",
                                  "disable-selected-options": "",
                                  "close-on-select": "",
                                  service: _vm.getSalesforceByOpportunity,
                                },
                                on: { change: _vm.setDealToPlatform },
                                scopedSlots: _vm._u(
                                  [
                                    {
                                      key: "option",
                                      fn: function ({ option }) {
                                        return [
                                          _vm._v(
                                            " " +
                                              _vm._s(
                                                _vm._f(
                                                  "dealNameAndTransactionIDLabel"
                                                )(option)
                                              ) +
                                              " "
                                          ),
                                        ]
                                      },
                                    },
                                  ],
                                  null,
                                  false,
                                  2127798127
                                ),
                                model: {
                                  value: _vm.editFrom.deal,
                                  callback: function ($$v) {
                                    _vm.$set(_vm.editFrom, "deal", $$v)
                                  },
                                  expression: "editFrom.deal",
                                },
                              }),
                            ],
                            1
                          ),
                          _c(
                            "div",
                            {
                              directives: [
                                {
                                  name: "show",
                                  rawName: "v-show",
                                  value: _vm.editFrom.idOpportunity,
                                  expression: "editFrom.idOpportunity",
                                },
                              ],
                              staticClass:
                                "flex flex-row justify-start wrap mt-4",
                            },
                            [
                              _c(
                                "sun-pill",
                                {
                                  attrs: { close: "", color: "gray" },
                                  on: { close: _vm.removeTransactionId },
                                },
                                [
                                  _vm._v(
                                    _vm._s(_vm.editFrom.idOpportunity) + " "
                                  ),
                                ]
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ]
                  },
                  proxy: true,
                },
                {
                  key: "footer",
                  fn: function () {
                    return [
                      _c(
                        "div",
                        { staticClass: "flex flex-row justify-between" },
                        [
                          _c("save-button", {
                            attrs: { disabled: _vm.disableSync },
                          }),
                          _c(
                            "sun-button",
                            {
                              staticClass:
                                "bg-gray-700 text-white rounded shadow-md hover:bg-gray-900 custom-p-1",
                              attrs: {
                                color: "red",
                                variant: "pill",
                                disabled: _vm.disableSync,
                              },
                              on: {
                                click: function ($event) {
                                  _vm.syncWithSalesforceModel = false
                                },
                              },
                            },
                            [
                              _c("span", { staticClass: "text-sm" }, [
                                _vm._v("Cancel"),
                              ]),
                            ]
                          ),
                        ],
                        1
                      ),
                    ]
                  },
                  proxy: true,
                },
              ],
              null,
              false,
              94934312
            ),
          })
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }